import rosaserosas from "./img/rosaserosas.png";
import watermelon from "./img/watermelon.png";
import magistroi from "./img/magistroi.png";

export default function Projetos() {
  return (
    <section className="main-section team">
      <div className="container">
        <h2>Últimos Projetos</h2>
        <h6> </h6>
        <div className="team-leader-block clearfix d-flex align-items-center justify-content-center">
          <div className="container-a1">
            <ul className="caption-style-1">
              <li>
                <img src={watermelon} />
                <div className="caption">
                  <div className="blur" />
                  <div className="caption-text">
                    <h1>Watermelon Seeds</h1>
                    <p>watermelon-seeds.pt</p>
                  </div>
                </div>
              </li>
              <li>
                <img src={rosaserosas} />
                <div className="caption">
                  <div className="blur" />
                  <div className="caption-text">
                    <h1>Rosas & Rosas</h1>
                    <p>rosaserosas.com</p>
                  </div>
                </div>
              </li>
              <li>
                <img src={magistroi} />
                <div className="caption">
                  <div className="blur" />
                  <div className="caption-text">
                    <h1>Coral Magistrói</h1>
                    <p>magistroi.pt</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
