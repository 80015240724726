import phone from "./img/iphone.png";

export default function Sobre() {
  return (
    <section className="main-section alabaster" id="sobre">
      <div className="container">
        <div className="row">
          <figure className="col-lg-5 col-sm-4 wow fadeInLeft">
            <img src={phone} />
          </figure>
          <div className="col-lg-7 col-sm-8 featured-work">
            <h2>Sobre mim</h2>
            <p className="padding-b">
              Para além da comida, a minha namorada e a minha família, tenho
              outras paixões, cada uma com o seu espaço no meu coração e um
              cantinho no meu horário. Todas juntas fazem de mim o que sou hoje
              e são também as minhas motivações diárias.{" "}
            </p>
            <div className="featured-box">
              <div className="featured-box-col1 wow fadeInRight delay-02s">
                <i className="fa-magic"></i>
              </div>
              <div className="featured-box-col2 wow fadeInRight delay-02s">
                <h3>Software</h3>
                <p>
                  Passo o meu dia-a-dia a explorar e conhecer novas soluções de
                  forma a melhorar o meu código. Nunca me canso!
                </p>
              </div>
            </div>
            <div className="featured-box">
              <div className="featured-box-col1 wow fadeInRight delay-04s">
                <i className="fa-hdd-o"></i>
              </div>
              <div className="featured-box-col2 wow fadeInRight delay-04s">
                <h3>Hardware</h3>
                <p>
                  A beleza de construir a máquina perfeita entre o consumo,
                  performance e preço é incontornável.{" "}
                </p>
              </div>
            </div>
            <div className="featured-box">
              <div className="featured-box-col1 wow fadeInRight delay-06s">
                <i className="fa-trophy"></i>
              </div>
              <div className="featured-box-col2 wow fadeInRight delay-06s">
                <h3>Futebol</h3>
                <p>
                  Não está tão presente na minha vida como no passado, mas de lá
                  trago aptidões de trabalho em equipa e experiências que dariam
                  um livro.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
