import mac from "./img/macbook-pro.png";

export default function Conhecimentos() {
  return (
    <section className="main-section">
      <div className="container">
        <div className="row">
          <h2>Conhecimentos</h2>
        </div>
        <div className="row">
          <h6>
            Competências adquiridos ao longo do estudo e por experiência
            profissional.
          </h6>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="service-list">
              <div className="service-list-col1">
                <i className="fa fa-cogs"></i>
              </div>
              <div className="service-list-col2">
                <h3>Backend Development</h3>
                <p>
                  Proficiente em desenvolvimento backend utilizando linguagens
                  como Go, C#, Java e PHP.
                </p>
              </div>
            </div>
            <div className="service-list">
              <div className="service-list-col1">
                <i className="fa-cloud-upload"></i>
              </div>
              <div className="service-list-col2">
                <h3>Java & GO</h3>
                <p>Experiência como formador do modulo introdutório ao Java.</p>
              </div>
            </div>
            <div className="service-list">
              <div className="service-list-col1">
                <i className="fa fa-code"></i>
              </div>
              <div className="service-list-col2">
                <h3>.NET Development</h3>
                <p>
                  Sólida experiência no desenvolvimento de ferramentas
                  utilizando C#, Microsoft .NET, Docker e API REST.
                </p>
              </div>
            </div>
            <div className="service-list">
              <div className="service-list-col1">
                <i className="fa fa-rss"></i>
              </div>
              <div className="service-list-col2">
                <h3>Full-Stack Development</h3>
                <p>Experiência desenvolvimento de APIs e aplicações PWA</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <figure className="text-right wow fadeInUp delay-02s">
              <img src={mac} />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
