import React, { useState } from "react";
import ipca from "./img/IPCA1.png";
import etg from "./img/etg.jpg";
import ff from "./img/ff.png";
import meireles from "./img/meireles.png";
import tic from "./img/tic.png";
import aubay from "./img/aubay.png";
import microsoft from "./img/microsoft.jpg";
import google from "./img/google.jpg";
import codacy from "./img/codacy.png";
import swiftSO from "./img/swiftso.png";

export default function Experiencia() {
  const [showEducacao, setShowEducacao] = useState(false);
  const [showCarreira, setShowCarreira] = useState(true);
  const [showExtra, setShowExtra] = useState(false);

  const handleSelecao = (userSelecao) => {
    switch (userSelecao) {
      case 1: {
        setShowEducacao(true);
        setShowCarreira(false);
        setShowExtra(false);
        break;
      }
      case 2: {
        setShowEducacao(false);
        setShowCarreira(true);
        setShowExtra(false);
        break;
      }
      case 3: {
        setShowEducacao(false);
        setShowCarreira(false);
        setShowExtra(true);
        break;
      }
      default: {
        setShowEducacao(true);
        setShowCarreira(false);
        setShowExtra(false);
        break;
      }
    }
  };

  return (
    <section className="main-section paddind" id="experiencia">
      <div className="container">
        <h2>Experiência</h2>
        <h6>
          O período de maior ganho em conhecimento e experiência é o período
          mais difícil da vida de alguém. - Dalai Lama
        </h6>
        <div className="portfolioFilter">
          <ul className="Portfolio-nav wow fadeIn delay-02s">
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => handleSelecao(2)}
                className={showCarreira ? "current" : ""}
              >
                Carreira
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => handleSelecao(1)}
                className={showEducacao ? "current" : ""}
              >
                Formação
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => handleSelecao(3)}
                className={showExtra ? "current" : ""}
              >
                Outros
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="portfolioContainer clearfix">
        {showCarreira ? (
          <div className="d-flex align-items-center justify-content-center">
            <div className="team-leader-box experiencia">
              <div className="team-leader wow fadeInDown delay-03s">
                <div className="team-leader-shadow">
                  <a className="#" target="_blank"></a>
                </div>
                <img src={swiftSO} />
                <ul>
                  <li>
                    <a
                      href="https://swiftso.tech/"
                      target="_blank"
                      className="fa fa-globe"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/swiftso/"
                      className="fa fa-linkedin"
                      target="_blank"
                    ></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-03s hidden-mobile">
                SwiftSO
              </h3>
              <span className="wow fadeInDown delay-03s">Team Lead</span>
            </div>
            <div className="team-leader-box experiencia">
              <div className="team-leader wow fadeInDown delay-03s">
                <div className="team-leader-shadow">
                  <a className="#" target="_blank"></a>
                </div>
                <img src={codacy} />
                <ul>
                  <li>
                    <a href="#" className="fa fa-globe"></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/codacy/"
                      className="fa fa-linkedin"
                      target="_blank"
                    ></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-03s hidden-mobile">Codacy</h3>
              <span className="wow fadeInDown delay-03s">
                Software Engineer - Go
              </span>
            </div>
            <div className="team-leader-box experiencia">
              <div className="team-leader wow fadeInDown delay-03s">
                <div className="team-leader-shadow">
                  <a className="#" target="_blank"></a>
                </div>
                <img src={ff} />
                <ul>
                  <li>
                    <a href="#" className="fa-globe"></a>
                  </li>
                  <li>
                    <a href="#" target="_blank" className="fa-facebook"></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-03s hidden-mobile">
                Farfetch
              </h3>
              <span className="wow fadeInDown delay-03s">
                Software Engineer - .NET
              </span>
            </div>
            <div className="team-leader-box experiencia">
              <div className="team-leader wow fadeInDown delay-03s">
                <div className="team-leader-shadow">
                  <a href="#" target="_blank"></a>
                </div>
                <img src={meireles} />
                <ul>
                  <li>
                    <a href="#" className="fa-twitter"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-facebook"></a>
                  </li>
                </ul>
              </div>

              <h3
                className="wow fadeInDown delay-03s hidden-mobile"
                style={{ fontSize: "80%" }}
              >
                Carnes Meireles do Minho S.A.
              </h3>
              <span className="wow fadeInDown delay-03s">
                Fullstack Developer
              </span>
            </div>
            <div className="team-leader-box experiencia">
              <div className="team-leader wow fadeInDown delay-03s">
                <div className="team-leader-shadow">
                  <a href="#" target="_blank"></a>
                </div>
                <img src={aubay} />
                <ul>
                  <li>
                    <a href="#" className="fa-twitter"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-facebook"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-chrome"></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-03s hidden-mobile">Aubay</h3>
              <span className="wow fadeInDown delay-03s">
                Consultor de Sistemas de Informação
              </span>
            </div>
            <div className="team-leader-box experiencia">
              <div className="team-leader wow fadeInDown delay-03s">
                <div className="team-leader-shadow">
                  <a href="#"></a>
                </div>
                <img src={tic} />
                <ul>
                  <li>
                    <a href="#" className="fa-twitter"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-facebook"></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-03s hidden-mobile">
                TIC - Informática
              </h3>
              <span className="wow fadeInDown delay-03s">
                Estágio CET - IPCA
              </span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {showEducacao ? (
          <div className="d-flex align-items-center justify-content-center">
            <div className="team-leader-box educacao">
              <div className="team-leader wow fadeInDown delay-03s">
                <div className="team-leader-shadow">
                  <a href="http://www.ipca.pt/" target="_blank"></a>
                </div>
                <img src={ipca} />
                <ul>
                  <li>
                    <a
                      href="https://twitter.com/ipca"
                      target="_blank"
                      className="fa-twitter"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/IPCA.Instituto.Politecnico/"
                      target="_blank"
                      className="fa-facebook"
                    ></a>
                  </li>
                </ul>
              </div>

              <h3 className="wow fadeInDown delay-03s hidden-mobile">IPCA</h3>
              <span className="wow fadeInDown delay-03s">
                Licenciatura Engenharia Sistemas Informáticos
              </span>
            </div>
            <div className="team-leader-box educacao">
              <div className="team-leader wow fadeInDown delay-06s">
                <div className="team-leader-shadow">
                  <a href="http://www.ipca.pt/" target="_blank"></a>
                </div>
                <img src={ipca} />
                <ul>
                  <li>
                    <a
                      href="https://twitter.com/ipca"
                      target="_blank"
                      className="fa-twitter"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/IPCA.Instituto.Politecnico/"
                      target="_blank"
                      className="fa-facebook"
                    ></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-06s hidden-mobile">IPCA</h3>
              <span className="wow fadeInDown delay-06s">
                CET Redes e Sistemas Informátivos
              </span>
            </div>
            <div className="team-leader-box educacao">
              <div className="team-leader wow fadeInDown delay-09s">
                <div className="team-leader-shadow">
                  <a href="http://www.etgbarcelos.com/" target="_blank"></a>
                </div>
                <img src={etg} />
                <ul>
                  <li>
                    <a href="#" className="fa-twitter"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-facebook"></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-09s hidden-mobile">ETG</h3>
              <span className="wow fadeInDown delay-09s">
                Técnico de Gestão
              </span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {showExtra ? (
          <div className="d-flex align-items-center justify-content-center">
            <div className="team-leader-box extra">
              <div className="team-leader wow fadeInDown delay-06s">
                <div className="team-leader-shadow">
                  <a href="#" target="_blank"></a>
                </div>
                <img src={google} />
                <ul>
                  <li>
                    <a href="#" className="fa-twitter"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-facebook"></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-06s hidden-mobile">Google</h3>
              <span className="wow fadeInDown delay-06s">
                Certificação Google Adwords
              </span>
            </div>
            <div className="team-leader-box extra">
              <div className="team-leader wow fadeInDown delay-06s">
                <div className="team-leader-shadow">
                  <a href="#" target="_blank"></a>
                </div>
                <img src={microsoft} />
                <ul>
                  <li>
                    <a href="#" className="fa-twitter"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-facebook"></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-06s hidden-mobile">
                Microsoft Virtual Academy
              </h3>
              <span className="wow fadeInDown delay-06s">
                Microsoft Networking Fundamentals
              </span>
            </div>
            <div className="team-leader-box extra">
              <div className="team-leader wow fadeInDown delay-06s">
                <div className="team-leader-shadow">
                  <a href="#" target="_blank"></a>
                </div>
                <img src={microsoft} />
                <ul>
                  <li>
                    <a href="#" className="fa-twitter"></a>
                  </li>
                  <li>
                    <a href="#" className="fa-facebook"></a>
                  </li>
                </ul>
              </div>
              <h3 className="wow fadeInDown delay-06s hidden-mobile">
                Microsoft Virtual Academy
              </h3>
              <span className="wow fadeInDown delay-06s">C# Fundamentals</span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </section>
  );
}
