import React, { useState, useEffect } from "react";
import navigate from "./img/navigate.png";
import logo from "./img/logo.png";
import "./css/animate.css";
import "./css/font-awesome.css";
import "./css/style.css";
import Nav from "../nav/Nav";
import Conhecimentos from "./conhecimentos/Conhecimentos";
import Sobre from "./sobre/Sobre";
import Experiencia from "./experiencia/Experiencia";
import Tecnologias from "./tecnologias/Tecnologias";
import Projetos from "./projetos/Projetos";
import FadeInSection from "../fadeInSection/FadeInSection";
import leetcode from "./icons/cib-leetcode.svg";
import pluralsight from "./icons/cib-pluralsight.svg";

export default function Main() {
  const [fixed, setFixed] = useState(false);
  const [topNav, setTopNav] = useState(0);

  const handleScroll = () => {
    if (document.documentElement.scrollTop >= topNav) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    setTopNav(document.querySelector("#inicio")?.offsetTop);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.addEventListener("scroll", handleScroll);
    };
  }, [topNav]);

  return (
    <div>
      <header
        className="header"
        id="header"
        style={{
          height: window.innerHeight,
        }}
      >
        <div className="container header-logo">
          <figure className="logo animated fadeInDown delay-07s">
            <a href="#test">
              <img src={logo} />
            </a>
          </figure>
          <br />
          <h1 className="animated fadeInDown delay-07s">Jonas Andrade</h1>
          <ul className="we-create animated fadeInUp delay-1s">
            <li>Freelancer | Software Engineer | Team Lead - SwiftSO</li>
          </ul>
          <figure className="logo animated fadeInDown delay-07s">
            <a href="#inicio">
              <img src={navigate} />
            </a>
          </figure>
        </div>
      </header>

      <Nav fixed={fixed} id="inicio" />
      <FadeInSection>
        <Conhecimentos />
      </FadeInSection>
      <FadeInSection>
        <Sobre />
      </FadeInSection>
      <FadeInSection>
        <Experiencia />
      </FadeInSection>
      <section className="main-section client-part" id="client">
        <div className="container">
          <b className="quote-right wow fadeInDown delay-03">
            <i className="fa fa-quote-right"></i>
          </b>
          <div className="row">
            <div className="col-lg-12">
              <p className="client-part-haead wow fadeInDown delay-05">
                Faz o que gostas e nunca terás de trabalhar o resto da vida!
              </p>
            </div>
          </div>
          <ul className="client wow fadeIn delay-05s">
            <li>
              <a href="#">
                <h3>Confúcio, filósofo</h3>
                <span>Pensamentos Perdidos</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <Tecnologias />
      <FadeInSection>
        <Projetos />
      </FadeInSection>
      <FadeInSection>
        <section className="main-section alabaster" id="curriculo">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 featured-work">
                <h2>Currículo</h2>
                <p className="padding-b">
                  Faz download do meu currículo, distribui pela cidade e pelos
                  teus amigos. Sê livre de o ler e dar sugestões.{" "}
                </p>
                <a
                  className="link animated fadeInUp delay-1s"
                  href="./JonasAndrade.pdf"
                  download={true}
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </section>
      </FadeInSection>
      <section className="business-talking">
        <div className="container">
          <h2>Vamos falar de negócios!</h2>
        </div>
      </section>
      <div className="container">
        <section className="main-section contact" id="contacto">
          <div className="row">
            <div className="col-lg-6 col-sm-7 wow fadeInLeft">
              <div className="contact-info-box address clearfix">
                <h3>
                  <i className="icon-map-marker"></i>Morada:
                </h3>
                <span>
                  Vila Boa
                  <br />
                  Barcelos - Braga.
                </span>
              </div>
              <div className="contact-info-box email clearfix">
                <h3>
                  <i className="fa-pencil"></i>email:
                </h3>
                <span>jonas@andrade.pt</span>
              </div>
              <div className="contact-info-box hours clearfix">
                <h3>
                  <i className="fa-clock-o"></i>Horário:
                </h3>
                <span>
                  <strong>Segunda-feira - Sexta-feira:</strong> 10h - 18h
                  <br />
                  <strong>Feriados:</strong> As pessoas trabalham nos feriados
                  agora?
                  <br />
                  <strong>Sábado - Domingo:</strong> É melhor nem falar nisso.
                </span>
              </div>
              <ul className="social-link">
                <li className="twitter">
                  <a href="https://twitter.com/MagicGodzilla12" target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li className="leetcode">
                  <a
                    href="https://leetcode.com/JonasAndrade12/"
                    target="_blank"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img src={leetcode} className="icon" alt="leetcode" />
                  </a>
                </li>
                <li className="pinterest">
                  <a
                    href="https://app.pluralsight.com/profile/jonas-andrade/"
                    target="_blank"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img
                      src={pluralsight}
                      className="icon invert"
                      alt="pluralsight"
                    />
                  </a>
                </li>
                <li className="gplus">
                  <a href="https://github.com/JonasAndrade12  " target="_blank">
                    <i className="fa fa-github"></i>
                  </a>
                </li>
                <li className="facebook">
                  <a
                    href="https://pt.linkedin.com/in/jonasandrade"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-5 wow fadeInUp delay-05s">
              <div className="flash-message"></div>
              <form className="form-horizontal">
                <input
                  type="text"
                  className="form-control input-text"
                  placeholder="O seu nome*"
                  required
                />
                <input
                  type="email"
                  className="form-control input-text"
                  placeholder="O seu email*"
                  required
                />
                <textarea
                  className="form-control input-text"
                  placeholder="Mensagem*"
                  required="required"
                  name="mensagem"
                  style={{ height: "80%" }}
                ></textarea>
                <button className="input-btn">Enviar</button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
