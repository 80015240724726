import React from "react";
import logoBlack from "./img/logo_black.png";

export default function Nav({ fixed }) {
  return (
    <div>
      <nav
        className={
          fixed ? "navbar main-nav-outer fixed-top" : "navbar main-nav-outer"
        }
        id="inicio"
      >
        <ul className="main-nav nav-fill">
          <li>
            <a href="#header">Home</a>
          </li>
          <li>
            <a href="#sobre">Sobre</a>
          </li>
          <li>
            <a href="#experiencia">Experiência</a>
          </li>
          <li className="small-logo hidden-mobile">
            <a href="#header">
              <img src={logoBlack} />
            </a>
          </li>
          <li>
            <a href="http://blog.andrade.pt/" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <a href="#curriculo">Curriculo</a>
          </li>
          <li>
            <a href="#contacto">Contactos</a>
          </li>
        </ul>
        <a className="res-nav_click" href="#">
          <i className="fa-bars"></i>
        </a>
      </nav>

      <div
        style={{
          display: fixed ? "block" : "none",
          height: "151px",
          float: "none",
        }}
      />
    </div>
  );
}
