import ionic from "./img/ionic.png";
import laravel from "./img/laravel.png";
import html from "./img/html.png";
import net from "./img/net.png";
import kotlin from "./img/kotlin.png";
import reactjs from "./img/reactjs.png";
import docker from "./img/docker.png";
import kafka from "./img/kafka.png";
import go from "./img/go.png";

export default function Tecnologias() {
  return (
    <div className="c-logo-part">
      <ul>
        <li>
          <a href="https://ionicframework.com/" className="hidden-mobile">
            <img src={ionic} className="img-fluid" />
          </a>
        </li>
        <li>
          <a
            href="https://dotnet.microsoft.com/en-us/"
            className="hidden-mobile"
          >
            <img src={net} className="img-fluid" />
          </a>
        </li>
        <li>
          <a href="https://laravel.com/" className="hidden-mobile">
            <img src={laravel} className="img-fluid" />
          </a>
        </li>
        <li>
          <a
            href="https://pt.wikipedia.org/wiki/HTML5"
            className="hidden-mobile"
          >
            <img src={html} className="img-fluid" />
          </a>
        </li>
        <li>
          <a href="https://go.dev/" className="hidden-mobile">
            <img src={go} className="img-fluid" />
          </a>
        </li>
        <li>
          <a href="https://kotlinlang.org/" className="hidden-mobile">
            <img src={kotlin} className="img-fluid" />
          </a>
        </li>
        <li>
          <a href="https://reactjs.org/" className="hidden-mobile">
            <img src={reactjs} className="img-fluid" />
          </a>
        </li>
        <li>
          <a href="https://www.docker.com/" className="hidden-mobile">
            <img src={docker} className="img-fluid" />
          </a>
        </li>
        <li>
          <a href="https://kafka.apache.org/" className="hidden-mobile">
            <img src={kafka} className="img-fluid" />
          </a>
        </li>
      </ul>
    </div>
  );
}
